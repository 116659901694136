<template>
	<main class="pt-0">
		<section>
			<BaseSearchInput v-model="searchText" placeholder="Sök namn eller födelsedatum">
				Sök namn eller födelsedatum
			</BaseSearchInput>
		</section>

		<section class="py-0" v-if="applications">
			<table class="list-table">
				<tr>
					<th class="w-1/4">Namn</th>
					<th class="w-1/4">Klubb</th>
					<th class="w-1/4">Telefonnummer</th>
					<th class="w-1/4">Datum</th>
				</tr>

				<tr v-for="application in applications" :key="application.id">
					<td>{{ application.first_name }} {{ application.last_name }}</td>
					<td>NÄRK</td>
					<td>{{ application.telephone }}</td>
					<td>{{ formatDateTimeString(application.created_at) }}</td>
				</tr>
			</table>

		</section>
	</main>
</template>

<script>
import basicApiCall from '@/core/helpers/basicApiCall'
import ApplicationService from '@/core/services/ApplicationService'
import {formatDateTimeString} from '@/core/helpers/date'

export default {
	name: 'ActivityApplications',
	data: () => ({
		searchText: '',
		applications: null,
	}),
	methods: {
		formatDateTimeString,
		async fetchApplications() {
			const {status, data} = await basicApiCall(ApplicationService.index(this.$route.params.id))
			if (status === 200) {
				this.applications = data
			}
		},
	},
	created() {
		this.fetchApplications()
	},
}
</script>

<style scoped>
</style>
